.carousel {
    position: unset;
}

.carousel img:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.carousel img {
    transition: transform 0.5s;
}

/* Next button's container */
.carousel > :nth-child(2) {
    right: 0 !important;
    top: 0 !important;
    border-radius: 0 !important;
    height: 4rem;
    padding: 0 !important;
}

/* Prev button's container */
.carousel > :nth-child(3) {
    right: 2.5rem !important;

    top: 0 !important;
    left: unset;
    height: 4rem;
}

.carousel > :nth-child(2):hover [aria-label='Next'],
.carousel > :nth-child(3):hover [aria-label='Previous'] {
    background-color: hsla(0, 0%, 100%, 0.6);
}

/* Aria label next should have top 0 */
.carousel [aria-label='Next'] {
    border-radius: 0 !important;
    padding: 0 !important;
    background-color: hsla(0, 0%, 100%, 0.6);
    top: 0.5rem !important;
    font-weight: 100;
    width: 2rem;
}

.carousel [aria-label='Next']:hover,
.carousel [aria-label='Previous']:hover {
    background-color: hsla(0, 0%, 100%, 0.6);
    color: black;
}

/* Aria label prev should have top 0 */
.carousel [aria-label='Previous'] {
    padding: 0 !important;
    border-radius: 0 !important;
    top: 0.5rem !important;
    background-color: hsla(0, 0%, 100%, 0.6);
    width: 2rem;
}

.slide {
    background: #fff;
}

/* Mobile queries */
@media screen and (max-width: 899px) {
    .carousel [aria-label='Next'],
    .carousel [aria-label='Previous'] {
        top: 0.25rem !important;
    }
}
